import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { Competitions } from '@/services/CompetitionsDataService'
import SysCompetition from '@/types/SysCompetition'

type dataReturnType = { staevners: any; error: any; totalPages: number; }

export default class theCompetitionList extends Vue {
  error: any = null
  private staevners: SysCompetition[] = []
  private currentListSortingOrder = 'staevne_start:asc'
  private oldSort = ''
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  private languageValue = 1
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  filterByTimeValue = 'Kommende stævner'
  filterByTimeOptions: string[] = ['Alle stævner', 'Kommende stævner', 'Afholdte stævner']
  competitionSearchInputValue = ''
  competitionSearchTerm = ''
  toDaysDate = new Date().toISOString()
  competitionHeld = `&staevne_start_gte=${this.toDaysDate}`

  readonly name : string = 'theCompetitionList'
  data (): dataReturnType {
    return {
      staevners: this.staevners,
      error: this.error,
      totalPages: this.totalPages
    }
  }

  public generateDateString (date: string) : string {
    return new Date(date).toISOString().split('T')[0]
  }

  public capitalize (str: string) : string {
    const cap = str.substring(0, 1).toUpperCase() + str.substring(1)
    return cap
  }

  public toDanishDateWithWeekDay (date: string, years = false) : string {
    let retVal = ''
    const weekDay = new Date(date).toLocaleDateString('da-DK', { weekday: 'long' })
    let dateString = new Date(date).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit' }).replaceAll('.', '/')

    if (years) {
      dateString = new Date(date).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
    }

    retVal = weekDay + ' d. ' + dateString

    return this.capitalize(retVal)
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveCompetitions()
  }

  @Watch('filterByTimeValue')
  onFilterByTimeValueChange (status: string) : void {
    if (status === 'Alle stævner') {
      this.competitionHeld = ''
      this.currentListSortingOrder = 'staevne_start:desc'
    }
    if (status === 'Kommende stævner') {
      this.competitionHeld = `&staevne_start_gte=${this.toDaysDate}`
      this.currentListSortingOrder = 'staevne_start:asc'
    }
    if (status === 'Afholdte stævner') {
      this.competitionHeld = `&staevne_start_lt=${this.toDaysDate}`
      this.currentListSortingOrder = 'staevne_start:desc'
    }

    this.retrieveCompetitions()
  }

  public toCompetitionInfo (id : number, competitionName : string, competitionDate : string) : void {
    const date = new Date(competitionDate).toLocaleDateString('da-DK').replaceAll('.', '-')
    const competitionSlug = id.toString() + '_' + this.convertToSlug(competitionName) + '-' + date

    console.log(competitionSlug)
    this.$router.push({ name: 'Competition', params: { slug: competitionSlug } })
  }

  public findCompetition () : void {
    console.log('Searching competitions.')

    this.competitionSearchTerm = this.competitionSearchInputValue
    this.retrieveCompetitions()
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCompetitions()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCompetitions()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'staevne_start:asc'
      this.oldSort = ''
      this.competitionSearchInputValue = ''
      this.competitionSearchTerm = ''
      this.currentPage = 1
      this.competitionHeld = ''
    }
    if (sortBy === 'staevnenavn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'staevne_navn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'staevne_navn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'dato') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'staevne_start:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'staevne_start:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'sidstefrist') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'staevne_sidstetilmelding:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'staevne_sidstetilmelding:desc'
        this.oldSort = ''
      }
    }

    this.retrieveCompetitions()
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public retrieveCompetitions () : void {
    console.log('Counting all active competitions')

    Competitions.CompetitionsDataService.getCount(`staevne_status=true${this.competitionHeld}`)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })

    console.log('Fetching competitions data from API.')

    if (this.competitionSearchTerm.length > 0) {
      Competitions.CompetitionsDataService.findBySearchTerm(true, true, this.competitionSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.competitionHeld}`)
        .then((response) => {
          this.staevners = response.data
          console.log(response.data)

          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Competitions.CompetitionsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.competitionHeld}`)
        .then((response) => {
          this.staevners = response.data
          console.log(response.data)

          this.forceRerenderCompetitionsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public forceRerenderCompetitionsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  async mounted () : Promise<void> {
    this.retrieveCompetitions()
  }
}
