
import { Options, Vue } from 'vue-class-component'
import theCompetitionList from '@/components/Frontend/CompetitionList/index.vue'

@Options({
  components: {
    theCompetitionList
  }
})

export default class CompetitionList extends Vue {}
